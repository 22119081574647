export default {
  '2024-02-29': {
    data: {
      comparisonData: [
        {
          time: '29/Feb/2024(Thu)',
          BinhDuong: '13,205',
          LongBien: '26,100',
          LeChan: '19,154',
          TanPhu: '28,856',
          HaDong: '21,208',
          BinhTan: '20,223',
        },
      ],
      comparisonMonthly2Data: [
        {
          LongBien_CurrentDate: '26,100',
          LongBien_SameWeek: '24,509',
          BinhTan_CurrentDate: '20,223',
          BinhDuong_SameDate: '12,855',
          HaDong_SameDate: '19,000',
          LeChan_CurrentDate: '19,154',
          BinhTan_SameWeek: '20,854',
          LeChan_SameWeek: '17,416',
          TanPhu_SameWeek: '27,796',
          TanPhu_CurrentDate: '28,856',
          BinhDuong_SameWeek: '12,677',
          BinhTan_SameDate: '20,296',
          TanPhu_SameDate: '26,576',
          time: '29/Feb/2024(Thu)',
          HaDong_CurrentDate: '21,208',
          LeChan_SameDate: '16,580',
          LongBien_SameDate: '24,253',
          BinhDuong_CurrentDate: '13,205',
          HaDong_SameWeek: '20,368',
        },
      ],
      legendData: ['29/Feb/2024(Thu)'],
      lastLegendData: ['28/Feb/2023(Tue)'],
      lastYearSameWeekData: ['02/Mar/2023(Thu)'],
      seriesData: [
        {
          countSort: 0,
          data: [28856],
          facilityInfoData: null,
          name: 'Tan Phu',
        },
        {
          countSort: 0,
          data: [13205],
          facilityInfoData: null,
          name: 'Binh Duong',
        },
        {
          countSort: 0,
          data: [26100],
          facilityInfoData: null,
          name: 'Long Bien',
        },
        {
          countSort: 0,
          data: [20223],
          facilityInfoData: null,
          name: 'Binh Tan',
        },
        {
          countSort: 0,
          data: [21208],
          facilityInfoData: null,
          name: 'Ha Dong',
        },
        {
          countSort: 0,
          data: [19154],
          facilityInfoData: null,
          name: 'Le Chan',
        },
      ],
    },
  },
  '2020-02-29': {
    data: {
      comparisonData: [
        {
          time: '29/Feb/2020(Sat)',
          BinhDuong: '22,167',
          LongBien: '34,193',
          LeChan: '0',
          TanPhu: '41,814',
          HaDong: '32,147',
          BinhTan: '29,388',
        },
      ],
      comparisonMonthly2Data: [
        {
          LongBien_CurrentDate: '34,193',
          LongBien_SameWeek: '44,711',
          BinhTan_CurrentDate: '29,388',
          BinhDuong_SameDate: '16,972',
          HaDong_SameDate: '0',
          LeChan_CurrentDate: '0',
          BinhTan_SameWeek: '49,482',
          LeChan_SameWeek: '0',
          TanPhu_SameWeek: '0',
          TanPhu_CurrentDate: '41,814',
          BinhDuong_SameWeek: '32,888',
          BinhTan_SameDate: '24,485',
          TanPhu_SameDate: '0',
          time: '29/Feb/2020(Sat)',
          HaDong_CurrentDate: '32,147',
          LeChan_SameDate: '0',
          LongBien_SameDate: '19,308',
          BinhDuong_CurrentDate: '22,167',
          HaDong_SameWeek: '0',
        },
      ],
      legendData: ['29/Feb/2020(Sat)'],
      lastLegendData: ['28/Feb/2019(Thu)'],
      lastYearSameWeekData: ['02/Mar/2019(Sat)'],
      seriesData: [
        {
          countSort: 0,
          data: [41814],
          facilityInfoData: null,
          name: 'Tan Phu',
        },
        {
          countSort: 0,
          data: [22167],
          facilityInfoData: null,
          name: 'Binh Duong',
        },
        {
          countSort: 0,
          data: [34193],
          facilityInfoData: null,
          name: 'Long Bien',
        },
        {
          countSort: 0,
          data: [29388],
          facilityInfoData: null,
          name: 'Binh Tan',
        },
        {
          countSort: 0,
          data: [32147],
          facilityInfoData: null,
          name: 'Ha Dong',
        },
        {
          countSort: 0,
          data: [0],
          facilityInfoData: null,
          name: 'Le Chan',
        },
      ],
    },
  },
  '2016-02-29': {
    data: {
      comparisonData: [
        {
          time: '29/Feb/2016(Mon)',
          BinhDuong: '15,115',
          LongBien: '21,891',
          LeChan: '0',
          TanPhu: '0',
          HaDong: '0',
          BinhTan: '0',
        },
      ],
      comparisonMonthly2Data: [
        {
          LongBien_CurrentDate: '21,891',
          LongBien_SameWeek: '0',
          BinhTan_CurrentDate: '0',
          BinhDuong_SameDate: '29,545',
          HaDong_SameDate: '0',
          LeChan_CurrentDate: '0',
          BinhTan_SameWeek: '0',
          LeChan_SameWeek: '0',
          TanPhu_SameWeek: '0',
          TanPhu_CurrentDate: '0',
          BinhDuong_SameWeek: '12,881',
          BinhTan_SameDate: '0',
          TanPhu_SameDate: '0',
          time: '29/Feb/2016(Mon)',
          HaDong_CurrentDate: '0',
          LeChan_SameDate: '0',
          LongBien_SameDate: '0',
          BinhDuong_CurrentDate: '15,115',
          HaDong_SameWeek: '0',
        },
      ],
      legendData: ['29/Feb/2016(Mon)'],
      lastLegendData: ['28/Feb/2015(Sat)'],
      lastYearSameWeekData: ['02/Mar/2015(Mon)'],
      seriesData: [
        {
          countSort: 0,
          data: [0],
          facilityInfoData: null,
          name: 'Tan Phu',
        },
        {
          countSort: 0,
          data: [15115],
          facilityInfoData: null,
          name: 'Binh Duong',
        },
        {
          countSort: 0,
          data: [21891],
          facilityInfoData: null,
          name: 'Long Bien',
        },
        {
          countSort: 0,
          data: [0],
          facilityInfoData: null,
          name: 'Binh Tan',
        },
        {
          countSort: 0,
          data: [0],
          facilityInfoData: null,
          name: 'Ha Dong',
        },
        {
          countSort: 0,
          data: [0],
          facilityInfoData: null,
          name: 'Le Chan',
        },
      ],
    },
  },
  '2024-02-28': {
    data: {
      comparisonData: [
        {
          time: '28/Feb/2024(Wed)',
          BinhDuong: '12,517',
          LongBien: '26,517',
          LeChan: '19,148',
          TanPhu: '28,386',
          HaDong: '20,887',
          BinhTan: '20,055',
        },
      ],
      comparisonMonthly2Data: [
        {
          LongBien_CurrentDate: '26,517',
          LongBien_SameWeek: '24,014',
          BinhTan_CurrentDate: '20,055',
          BinhDuong_SameDate: '12,855',
          HaDong_SameDate: '19,000',
          LeChan_CurrentDate: '19,148',
          BinhTan_SameWeek: '20,027',
          LeChan_SameWeek: '16,658',
          TanPhu_SameWeek: '26,616',
          TanPhu_CurrentDate: '28,386',
          BinhDuong_SameWeek: '12,992',
          BinhTan_SameDate: '20,296',
          TanPhu_SameDate: '26,576',
          time: '28/Feb/2024(Wed)',
          HaDong_CurrentDate: '20,887',
          LeChan_SameDate: '16,580',
          LongBien_SameDate: '24,253',
          BinhDuong_CurrentDate: '12,517',
          HaDong_SameWeek: '19,297',
        },
      ],
      legendData: ['28/Feb/2024(Wed)'],
      lastLegendData: ['28/Feb/2023(Tue)'],
      lastYearSameWeekData: ['01/Mar/2023(Wed)'],
      seriesData: [
        {
          countSort: 0,
          data: [28386],
          facilityInfoData: null,
          name: 'Tan Phu',
        },
        {
          countSort: 0,
          data: [12517],
          facilityInfoData: null,
          name: 'Binh Duong',
        },
        {
          countSort: 0,
          data: [26517],
          facilityInfoData: null,
          name: 'Long Bien',
        },
        {
          countSort: 0,
          data: [20055],
          facilityInfoData: null,
          name: 'Binh Tan',
        },
        {
          countSort: 0,
          data: [20887],
          facilityInfoData: null,
          name: 'Ha Dong',
        },
        {
          countSort: 0,
          data: [19148],
          facilityInfoData: null,
          name: 'Le Chan',
        },
      ],
    },
  },
  '2020-02-28': {
    data: {
      comparisonData: [
        {
          time: '28/Feb/2020(Fri)',
          BinhDuong: '15,859',
          LongBien: '24,590',
          LeChan: '0',
          TanPhu: '31,869',
          HaDong: '20,030',
          BinhTan: '22,340',
        },
      ],
      comparisonMonthly2Data: [
        {
          LongBien_CurrentDate: '24,590',
          LongBien_SameWeek: '22,567',
          BinhTan_CurrentDate: '22,340',
          BinhDuong_SameDate: '16,972',
          HaDong_SameDate: '0',
          LeChan_CurrentDate: '0',
          BinhTan_SameWeek: '29,661',
          LeChan_SameWeek: '0',
          TanPhu_SameWeek: '0',
          TanPhu_CurrentDate: '31,869',
          BinhDuong_SameWeek: '17,863',
          BinhTan_SameDate: '24,485',
          TanPhu_SameDate: '0',
          time: '28/Feb/2020(Fri)',
          HaDong_CurrentDate: '20,030',
          LeChan_SameDate: '0',
          LongBien_SameDate: '19,308',
          BinhDuong_CurrentDate: '15,859',
          HaDong_SameWeek: '0',
        },
      ],
      legendData: ['28/Feb/2020(Fri)'],
      lastLegendData: ['28/Feb/2019(Thu)'],
      lastYearSameWeekData: ['01/Mar/2019(Fri)'],
      seriesData: [
        {
          countSort: 0,
          data: [31869],
          facilityInfoData: null,
          name: 'Tan Phu',
        },
        {
          countSort: 0,
          data: [15859],
          facilityInfoData: null,
          name: 'Binh Duong',
        },
        {
          countSort: 0,
          data: [24590],
          facilityInfoData: null,
          name: 'Long Bien',
        },
        {
          countSort: 0,
          data: [22340],
          facilityInfoData: null,
          name: 'Binh Tan',
        },
        {
          countSort: 0,
          data: [20030],
          facilityInfoData: null,
          name: 'Ha Dong',
        },
        {
          countSort: 0,
          data: [0],
          facilityInfoData: null,
          name: 'Le Chan',
        },
      ],
    },
  },
  '2016-02-28': {
    data: {
      comparisonData: [
        {
          time: '28/Feb/2016(Sun)',
          BinhDuong: '38,861',
          LongBien: '69,056',
          LeChan: '0',
          TanPhu: '0',
          HaDong: '0',
          BinhTan: '0',
        },
      ],
      comparisonMonthly2Data: [
        {
          LongBien_CurrentDate: '69,056',
          LongBien_SameWeek: '0',
          BinhTan_CurrentDate: '0',
          BinhDuong_SameDate: '29,545',
          HaDong_SameDate: '0',
          LeChan_CurrentDate: '0',
          BinhTan_SameWeek: '0',
          LeChan_SameWeek: '0',
          TanPhu_SameWeek: '0',
          TanPhu_CurrentDate: '0',
          BinhDuong_SameWeek: '36,585',
          BinhTan_SameDate: '0',
          TanPhu_SameDate: '0',
          time: '28/Feb/2016(Sun)',
          HaDong_CurrentDate: '0',
          LeChan_SameDate: '0',
          LongBien_SameDate: '0',
          BinhDuong_CurrentDate: '38,861',
          HaDong_SameWeek: '0',
        },
      ],
      legendData: ['28/Feb/2016(Sun)'],
      lastLegendData: ['28/Feb/2015(Sat)'],
      lastYearSameWeekData: ['01/Mar/2015(Sun)'],
      seriesData: [
        {
          countSort: 0,
          data: [0],
          facilityInfoData: null,
          name: 'Tan Phu',
        },
        {
          countSort: 0,
          data: [38861],
          facilityInfoData: null,
          name: 'Binh Duong',
        },
        {
          countSort: 0,
          data: [69056],
          facilityInfoData: null,
          name: 'Long Bien',
        },
        {
          countSort: 0,
          data: [0],
          facilityInfoData: null,
          name: 'Binh Tan',
        },
        {
          countSort: 0,
          data: [0],
          facilityInfoData: null,
          name: 'Ha Dong',
        },
        {
          countSort: 0,
          data: [0],
          facilityInfoData: null,
          name: 'Le Chan',
        },
      ],
    },
  },
}
